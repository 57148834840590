import wave from '../assets/wave.svg'
import dazn from '../assets/dazn.svg'
import solarturbines from '../assets/solarturbines.svg'
import caterpillar from '../assets/caterpillar.svg'
import databook from '../assets/databook.svg'
import './OurWork.css'
import { Outlet, Link } from "react-router-dom";

function OurWork() {

    return (
        <div className='ourWorkPage'>
            <div className='header'>
                <div>
                    <Link to="/mainpage"><img src={wave} alt='' className='logoImage' width='300' height='150'/></Link>
                </div>
                <div>
                    <Link to="/mainpage"><button className='backButton'>BACK</button></Link>
                </div>
            </div>
            <div className='content'>
                <div>
                    <h1>At Waveward, we specialize in taking on substantial, long-term projects. We take pride in seamlessly integrating as a part of our client's larger team, collaborating closely to achieve collective goals. We believe in the power of sustained strategic vision and unwavering commitment.</h1>
                </div>
                <div className='logosCompanies'>
                    <img className='daznImage' src={dazn}/>
                    <img className='solarturbinesImage' src={solarturbines}/>
                    <img className='databookImage' src={databook}/>
                    <img className='caterpillarImage' src={caterpillar}/>
                </div>
            </div>
        </div>
    )
}

export default OurWork;