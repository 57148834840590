import React from 'react'
import wave from '../assets/wave.svg'
import './Services.css'
import frontend from '../assets/frontend.png'
import backend from '../assets/backend.png'
import uxdesigner from '../assets/uxdesigner.png'
import { Outlet, Link } from "react-router-dom";


function Services() {

    return (
        <div>
            <div className='servicesPage'>
            <div className='header'>
                <div className='buttonDiv'>
                    <Link to="/mainpage"><img src={wave} alt='' className='logoImage' width='300' height='150'/></Link>
                </div>
                <div>
                    <Link to="/mainpage"><button className='backButton'>BACK</button></Link>
                </div>
            </div>
                <div className='text'>At Waveward, we specialize in crafting dynamic web solutions tailored to your unique needs. Our expertise lies in harnessing the power of cutting-edge technologies like React and Node.js to deliver exceptional results. Whether it's end-to-end development of complete projects or handling specific components of applications, we're adept at both</div>
                <div className='serviceCardSection'>
                    <div className='serviceCard'>
                        <div className='serviceImage'><img src={frontend} alt=''/></div>
                        <h3>Frontend Development</h3>
                        <p>Our Frontend Development service is tailored to create intuitive, engaging, and visually appealing user interfaces that enhance the overall user experience of your web or mobile applications. A well-designed frontend is crucial in attracting and retaining users, and it plays a pivotal role in the success of your digital products.</p>
                    </div>
                    <div className='serviceCard'>
                        <div className='serviceImage'><img src={backend} alt=''/></div>
                        <h3>Backend Development</h3>
                        <p>Our Backend Development Services empower your digital presence by providing the robust, efficient, and scalable infrastructure that powers your applications and systems. Our experienced team of backend developers and engineers specializes in creating the backbone of your digital products, ensuring they run smoothly and securely.</p>
                    </div>
                    <div className='serviceCard'>
                        <div className='serviceImage'><img src={uxdesigner} alt=''/></div>
                        <h3>UX design</h3>
                        <p>Our UX Design Services are dedicated to creating exceptional and user-centric digital experiences that captivate, engage, and retain your audience. We believe that a seamless and intuitive user experience is paramount to the success of any digital product, be it a website, mobile app, or software platform.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services;