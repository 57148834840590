import React from 'react'
import './Contact.css'
import linkedin from '../assets/linkedin.svg'
import phone from '../assets/phone.svg'
import email from '../assets/email.svg'
import wave from '../assets/wave.svg'
import social from '../assets/socialmedia.svg'
import { Outlet, Link } from "react-router-dom";

function Contact() {

    return (
        <div className='contactPage'>
            <div className='header'>
                <div>
                    <Link to="/mainpage"><img src={wave} alt='' className='logoImage' width='300' height='150'/></Link>
                </div>
                <div>
                    <Link to="/mainpage"><button className='backButton'>BACK</button></Link>
                </div>
            </div>
            <h1>Get a quote or set up a consultation.</h1>
            <div className='contactCard'>
                <div className='nameOfOption'>
                    <img className='contactImage' src={phone} alt='' />
                    <h3>PHONE</h3>
                </div>
                <div className='phoneNumber'><a href="tel:+421902223347">+421902223347</a></div>
            </div>
            <div className='contactCard'>
                <div className='nameOfOption'>
                    <img className='contactImage' src={email} alt='' />
                    <h3>EMAIL</h3>
                </div>
                <div className='emailAddress'><a href='mailto:marek.ondrejcik@gmail.com'>business@waveward.com</a></div>
            </div>
            <div className='contactCard'>
                <div className='nameOfOption'>
                    <img className='contactImage' src={social} alt='' />
                    <h3>SOCIAL</h3> 
                </div>
                <a href='https://www.linkedin.com/company/waveward/about/' target="_blank"><img className='linkedinAccount' src={linkedin} alt=''/></a>
            </div>
        </div>
    )
}

export default Contact;