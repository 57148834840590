import React from 'react';
import wave from '../assets/wave.svg'
import './MainPage.css'
import { Outlet, Link } from "react-router-dom";

function MainPage() {
    

    return (
        <div>
            <div class='box'>
                <div class='wave -one'> </div>
                <div class='wave -two'></div>
                <div class='wave -three'></div>
            </div>
            <div className='mainPage'>
                <div>
                    <img src={wave} alt='' className='logoImage' width='300' height='150'/>
                </div>
                <section className=''>
                    <section className='mainTitle'>
                        <div className='title'>SOFTWARE</div>
                        <div className='title'>DEVELOPMENT</div>
                    </section>
                    <section className='button-container'>
                        <Link to="/ourwork"><button className="button-36" role="button">Our work</button></Link>
                        <Link to="/services"><button className="button-36" role="button">Services</button></Link>
                        <Link to="/contact"><button className="button-36" role="button">Contact</button></Link>
                    </section>
                </section>
            </div>
            <Outlet />
        </div>
    )
}

export default MainPage;